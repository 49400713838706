document.addEventListener('DOMContentLoaded', () => {
    const mainSwiper = new Swiper('.slider-main', {
        loop: true,
        autoplay: {
            delay: 5000
        },
        pagination: {
            el: '.swiper-pagination',
            clickable: true
        }
    })

    const secondarySwiper = new Swiper('.slider-secondary', {
        loop: true,
        autoplay: {
            delay: 5000
        },
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev'
        },

        pagination: {
            el: '.swiper-pagination',
            clickable: true
        }
    })

    const sectionThumb = new Swiper('.slider-section-thumb', {
        spaceBetween: 10,
        slidesPerView: 4,
        freeMode: true,
        watchSlidesProgress: true
    })

    const section = new Swiper('.slider-section', {
        loop: true,
        autoplay: {
            delay: 5000
        },
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev'
        },

        pagination: {
            el: '.swiper-pagination',
            clickable: true
        },
        thumbs: {
            swiper: sectionThumb
        }
    })

    const sectionThumbNv = new Swiper('.slider-section-thumb-nv', {
        spaceBetween: 10,
        slidesPerView: 4,
        freeMode: true,
        watchSlidesProgress: true
    })

    const sectionNv = new Swiper('.slider-section-nv', {
        loop: true,
        autoplay: {
            delay: 5000
        },
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev'
        },

        pagination: {
            el: '.swiper-pagination',
            clickable: true
        },
        thumbs: {
            swiper: sectionThumbNv
        }
    })

    const headbanner = new Swiper('.slider-headbanner', {
        loop: true,
        autoplay: {
            delay: 5000
        },
        pagination: {
            el: '.swiper-pagination',
            clickable: true
        }
    })
})
