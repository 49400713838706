jQuery(document).ready(function ($) {
    $('.paroller').paroller()

    $('.jelect').jelect()

    $('.enable-form').on('click', function (e) {
        e.preventDefault()
        $('.featured.contact-featured .featured__content').hide()
        $('.featured.contact-featured .featured__form').show()
    })

    $('.enable-filter').on('click', function () {
        $('#filter-menu').addClass('show')
    })

    $('#filter-menu .close').on('click', function () {
        $('#filter-menu').removeClass('show')
    })

    $('.gallery-top .swiper-button-next, .gallery-thumbs img').on('click', function (e) {
        e.preventDefault()
        galleryThumbs.slideNext()
        galleryTop.slideNext()
    })

    $('.gallery-top .swiper-button-prev').on('click', function (e) {
        e.preventDefault()
        console.log(e)
        galleryThumbs.slidePrev()
        galleryTop.slidePrev()
    })

    // Add listeners for pull up trigger
    LoadTrigger: {
        const $trigger = $('#sticky_trigger')

        $trigger.waypoint(
            function (direction) {
                if (direction === 'down') { }
            }, {
            offset: '50%'
        }
        )

        $trigger.waypoint(
            function (direction) {
                if (direction === 'up') { }
            }, {
            offset: '50%'
        }
        )
    }

    // Add listeners & hooks for menu
    // SmoothScrool: {
    //    $('a[href*="#"]')
    //    .on('click', function (e) {
    //        e.preventDefault()
    //        e.stopPropagation()
    //
    //        if (!$(this.hash).length) {
    //            return true
    //        }
    //
    //        const target = this.hash
    //        const $target = $(target)
    //
    //        $('html, body')
    //            .stop()
    //            .animate({
    //                    scrollTop: $target.offset().top
    //                },
    //                900,
    //                'swing',
    //                function () {
    //                    window.location.hash = target
    //                }
    //            )
    //    })
    // }
    $('.social-feed-container').socialfeed({
        facebook: {
            accounts: ['@avto.slak'], // Array: Specify a list of accounts from which to pull wall posts
            limit: 3, // Integer: max number of posts to load
            access_token: '182513952183717|uOid1HPUANrTj5FyQlpuJJ_4VwA' // String: "APP_ID|APP_SECRET"
        },

        // GENERAL SETTINGS
        length: 400,
        show_media: true, // Boolean: if false, doesn't display any post images
        media_min_width: 300, // Integer: Only get posts with images larger than this
        template: THEME_DIR + '/feed.html'
    })

    /// //////////////////////////////////////////////////////////////
    // PRICE RANGE
    /// //////////////////////////////////////////////////////////////

    if ($('#slider-price').length > 0) {
        const slider = document.getElementById('slider-price')
        let min_price = document.getElementById('pix-min-price').value
        let max_price = document.getElementById('pix-max-price').value
        const max_slider_price = document.getElementById('pix-max-slider-price')
            .value
        // var symbol_price = document.getElementById('pix-currency-symbol').value;
        min_price = min_price == '' ? 0 : min_price
        max_price = max_price == '' ? max_slider_price : max_price

        noUiSlider.create(slider, {
            start: [min_price, max_price],
            step: 1000,
            connect: true,
            range: {
                min: 0,
                max: Number(max_slider_price)
            },
            format: {
                to: function (value) {
                    return value
                },
                from: function (value) {
                    return value
                }
            }
        })

        const pValues_price = [
            document.getElementById('slider-price_min'),
            document.getElementById('slider-price_max')
        ]

        slider.noUiSlider.on('update', function (values, handle) {
            pValues_price[handle].value = values[handle]
        })

        slider.noUiSlider.on('change', function (values, handle) {
            $(pValues_price[handle]).trigger('change')
        })
    }

    /// //////////////////////////////////////////////////////////////
    // YEAR RANGE
    /// //////////////////////////////////////////////////////////////

    if ($('#slider-year').length > 0) {
        const slider_year = document.getElementById('slider-year')
        let min_year = document.getElementById('pix-min-year').value
        let max_year = document.getElementById('pix-max-year').value
        const max_slider_year = document.getElementById('pix-max-slider-year').value
        min_year = min_year == '' ? 1950 : min_year
        max_year = max_year == '' ? max_slider_year : max_year

        noUiSlider.create(slider_year, {
            start: [min_year, max_year],
            step: 1,
            connect: true,
            range: {
                min: 1950,
                max: Number(max_slider_year)
            },
            format: {
                to: function (value) {
                    return value
                },
                from: function (value) {
                    return value
                }
            }
        })

        const pValues_year = [
            document.getElementById('slider-year_min'),
            document.getElementById('slider-year_max')
        ]

        slider_year.noUiSlider.on('update', function (values, handle) {
            pValues_year[handle].value = values[handle]
        })

        slider_year.noUiSlider.on('change', function (values, handle) {
            $(pValues_year[handle]).trigger('change')
        })
    }

    /// //////////////////////////////////////////////////////////////
    //   MILEAGE RANGE
    /// //////////////////////////////////////////////////////////////

    if ($('#slider-mileage').length > 0) {
        const slider_mileage = document.getElementById('slider-mileage')
        let min_mileage = document.getElementById('pix-min-mileage').value
        let max_mileage = document.getElementById('pix-max-mileage').value
        const max_slider_mileage = document.getElementById('pix-max-slider-mileage')
            .value
        min_mileage = min_mileage == '' ? 0 : min_mileage
        max_mileage = max_mileage == '' ? max_slider_mileage : max_mileage

        noUiSlider.create(slider_mileage, {
            start: [min_mileage, max_mileage],
            step: 10000,
            connect: true,
            range: {
                min: 0,
                max: Number(max_slider_mileage)
            },
            format: {
                to: function (value) {
                    return value
                },
                from: function (value) {
                    return value
                }
            }
        })
        const pValues_mileage = [
            document.getElementById('slider-mileage_min'),
            document.getElementById('slider-mileage_max')
        ]
        slider_mileage.noUiSlider.on('update', function (values, handle) {
            pValues_mileage[handle].value = values[handle]
        })
        slider_mileage.noUiSlider.on('change', function (values, handle) {
            $(pValues_mileage[handle]).trigger('change')
        })
    }

    /// //////////////////////////////////////////////////////////////
    //   ENGINE RANGE
    /// //////////////////////////////////////////////////////////////

    if ($('#slider-engine').length > 0) {
        const slider_engine = document.getElementById('slider-engine')
        let min_engine = document.getElementById('pix-min-engine').value
        let max_engine = document.getElementById('pix-max-engine').value
        const max_slider_engine = document.getElementById('pix-max-slider-engine')
            .value
        min_engine = min_engine == '' ? 0 : min_engine
        max_engine = max_engine == '' ? max_slider_engine : max_engine
        noUiSlider.create(slider_engine, {
            start: [min_engine, max_engine],
            step: 0.1,
            connect: true,
            range: {
                min: 0,
                max: Number(max_slider_engine)
            }
            // Full number format support.
        })
        const pValues_engine = [
            document.getElementById('slider-engine_min'),
            document.getElementById('slider-engine_max')
        ]
        slider_engine.noUiSlider.on('update', function (values, handle) {
            pValues_engine[handle].value = values[handle]
        })
        slider_engine.noUiSlider.on('change', function (values, handle) {
            $(pValues_engine[handle]).trigger('change')
        })
    }
})

const menu = document.getElementById('fixed')

/* window.addEventListener("scroll", function () {
    if (window.scrollY > 50) {
        //menu.classList.add("stick");
    } else {
        //menu.classList.remove("stick");
    }
}); */


const app = (() => {
    let body
    let menu
    let icon
    let activeFlag

    const init = () => {
        body = document.querySelector('body')
        menu = document.querySelectorAll('.navmain__list-item a.nested')
        icon = document.querySelector('.menu-icon')
        dropdown = document.querySelector('.dropdown a')
        dropdown_mobile = document.querySelector('.dropdown-mobile a')
        menuItems = document.querySelectorAll('.nav__list-item')

        applyListeners()
    }

    const applyListeners = () => {
        menu.forEach(function (e) {
            e.addEventListener('click', () => {
                // $(icon).show()

                if (activeFlag) {
                    toggleClass()
                } else {
                    toggleClass(body, 'nav-active')
                }
                activeFlag = true
            })
        })

        icon.addEventListener('click', () => {
            toggleClass(body, 'nav-active')
            // $(icon).hide()
            activeFlag = false
        })

        dropdown.addEventListener('click', e => {
            e.preventDefault()
            toggleClass(body, 'nav-active')
            // $(icon).hide()
            activeFlag = false
        })

        dropdown_mobile.addEventListener('click', e => {
            e.preventDefault()
            toggleClass(body, 'navmobile-active')
            // $(icon).hide()
            activeFlag = false
        })
    }

    const toggleClass = (element, stringClass) => {
        if (element.classList.contains(stringClass)) {
            element.classList.remove(stringClass)
        } else {
            element.classList.add(stringClass)
        }
    }

    init()
})()

const galleryThumbs = new Swiper('.gallery-thumbs', {
    spaceBetween: 0,
    slidesPerView: 1,
    width: 280,
    initialSlide: 1,
    effect: 'fade',
    loop: true,
    touchRatio: 0,
    navigation: {
        nextEl: '.gswiper-button-next',
        prevEl: '.gswiper-button-prev'
    },
    autoplay: {
        delay: 5000
    },
    breakpoints: {
        575: {
            slidesPerView: 1,
            spaceBetween: 0,
            width: 190,
            touchRatio: 0
        }
    }
})

const galleryTop = new Swiper('.gallery-top', {
    spaceBetween: 0,
    loop: true,
    autoplay: {
        delay: 5000
    },
    navigation: {
        nextEl: '.gswiper-button-next',
        prevEl: '.gswiper-button-prev'
    },

    575: {}
})

const galleryThumbsSingle = new Swiper('.single-gallery-thumbs', {
    spaceBetween: 10,
    slidesPerView: 4,
    loop: true,
    freeMode: false,
    watchSlidesVisibility: true,
    watchSlidesProgress: true
})
const galleryTopSingle = new Swiper('.single-gallery-top', {
    spaceBetween: 0,
    loop: true,
    navigation: {
        nextEl: '.sgswiper-button-next',
        prevEl: '.sgswiper-button-prev'
    },
    thumbs: {
        swiper: galleryThumbsSingle
    }
})

const cswiper = new Swiper('.cslider-container', {
    loop: true,
    navigation: {
        nextEl: '.cswiper-button-next',
        prevEl: '.cswiper-button-prev'
    }
})

const nswiper = new Swiper('.nslider-container', {
    loop: true,
    navigation: {
        nextEl: '.nswiper-button-next',
        prevEl: '.nswiper-button-prev'
    }
})


//
// document.querySelectorAll('.nouirange').forEach(function (el) {
//    const htmlinsert = document.createElement('div')
//    const realmininput = el.querySelector('.min')
//    const realmaxinput = el.querySelector('.max')
//    realmininput.style.display = 'none '
//    realmaxinput.style.display = 'none '
//    const min = realmininput.getAttribute('min')
//    const max = realmaxinput.getAttribute('max')
//    el.appendChild(htmlinsert)
//
//    noUiSlider.create(htmlinsert, {
//        start: [realmininput.value, realmaxinput.value],
//        connect: true,
//        range: {
//            'min': Number(min),
//            'max': Number(max)
//        }
//    })
//    htmlinsert.noUiSlider.on('change', function (values) {
//        const rangevals = values
//        realmininput.value = String(values[0])
//        realmaxinput.value = String(values[1])
//    })
// })
